.admin-menu {
    background: #120f27;
    height: calc(100vh - 70px);
    max-width: 270px;
    overflow: auto;
    padding-bottom: 30px;
    position: fixed;
    width: 100%;
    -webkit-overflow-scrolling: touch;  
    scroll-behavior: smooth;
}
.admin-menu::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  .admin-menu::-webkit-scrollbar-track {
    background: rgb(170 170 170 / 20%);
  }
  
  .admin-menu::-webkit-scrollbar-thumb {
    background-color: #2E8BE6;
  }

.menu-list {
    padding-top: 15px;
}

.menu-list ul {
    margin: 0;
    padding: 0;
}

.menu-list ul a {
    padding: 15px 15px;
    padding-left: 25px;
    display: flex;
    gap: 10px;
    color: #fff;
    text-decoration: none;
    transition: all 0.5s;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    position: relative;
}

.menu-list ul a:hover {
    color: #E80054 !important;
}

.menu-list ul a.active {
    color: #E80054 !important;
}

.menu-list ul a.active::before {
    content: "";
    background: #E80054;
    width: 2px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 20px;
}

.menu-list ul a:hover svg path,
.menu-list ul a:hover svg circle,
.menu-list ul a:hover svg rect,
.menu-list ul a.active svg path,
.menu-list ul a.active svg circle,
.menu-list ul a.active svg rect {
    stroke: #E80054;
}
.menu-list ul  a.game-nav-icon:hover svg path,
.menu-list ul  a.game-nav-icon.active svg path{
    fill: #E80054;
    stroke: unset;
}
.request-cont{
    background: rgb(232 0 84 / 20%);
    font-weight: 600;
    font-size: 8px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #E80054;
    border-radius: 100%;
    position: absolute;
    right: 15px;
    width: 20px;
    height: 20px;
}