.withdraw-page .confirm-btn {
  max-width: 120px;
}

.currency-type {
  max-width: 130px;
  width: 100%;
}

.request-date {
  max-width: 155px;
  width: 100%;
}

.request-date p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(255 255 255 / 60%);
  margin: 0;
  padding-bottom: 3px;
}

.search-wrapper-withdraw {
  position: absolute;
  width: 100%;
  top: 60px;
}

.request-date h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.transaction-amt-grid {
  display: flex;
  max-width: 150px;
  width: 100%;
}

.transaction-amt p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(255 255 255 / 60%);
  margin: 0;
  padding-bottom: 3px;
}

.transaction-amt h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}

.withdarw-popup .modal-backdrop {
  background: #0e0d27;
  opacity: 0.8 !important;
}

.withdarw-popup .modal-content {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 850px;
  margin: 0 auto;
}

.withdarw-popup .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}

.withdarw-popup .btn-close {
  background: url("../../assets//images/table/close-icon.svg") center;
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
}

.withdarw-popup .btn-close:focus {
  box-shadow: none;
}

.withdarw-popup .modal-body {
  padding: 0px 0px 80px 0px;
  text-align: center;
  color: #0e0d27;
}

.transaction-heading {
  padding: 40px 0;
  max-width: 580px;
  margin: 0px auto;
}

.transaction-heading h6 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 30px;
}

.transaction-heading p {
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  color: #2e8be6;
  margin: 0;
}

.cashtag-heading .transaction-heading h6 {
  padding: 0 !important;
}

.cashtag-link {
  background: #f3f3f4;
  border-radius: 10px;
  padding: 20px;
  max-width: 410px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cashtag-link h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
  display: flex;
  width: 100%;
}

.cashtag-link span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: rgb(14 13 39 / 50%);
  padding-left: 10px;
  width: calc(100% - 133px);
  overflow: hidden;
}

.link-icon {
  cursor: pointer;
}

.qr-box {
  margin: 30px auto;
  max-width: 340px;
  padding: 20px;
  border: 1px solid #f3f3f4;
  border-radius: 10px;
}

.qr-info h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 10px;
}

.qr-info p {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: rgb(14 13 39 / 50%);
  margin: 0;
}

.popup-btn-grid {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 40px;
}

.popup-cancel-btn {
  background: transparent;
  max-width: 190px;
  width: 100%;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #0e0d27;
  padding: 16px 6px;
}

.popup-cancel-btn:hover,
.popup-cancel-btn:focus {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  color: #0e0d27;
}

.popup-confirm-btn {
  max-width: 190px;
  width: 100%;
  border: none;
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 16px 6px;
}

.popup-confirm-btn:hover,
.popup-confirm-btn:focus {
  border: none;
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  color: #ffffff;
}

.cashtag-kyc-grid {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 40px;
  width: 100%;
  gap: 20px;
}

.cashtag-kyc-grid .cashtag-link {
  max-width: 50% !important;
  width: 100%;
  margin: 0;
}

.cashtag-kyc-grids {
  padding: 20px 40px;
}

.cashtag-kyc-grids .cashtag-link {
  max-width: 100%;
  width: 100%;
}

.cashtag-kyc-id {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 20px 40px;
  width: 100%;
}

.cashtag-kyc-id .qr-wrapper .qr-box {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  border-radius: 0;
}

.cashtag-kyc-id .qr-wrapper .qr-box img {
  max-width: 300px;
  width: 100%;
}

.cashtag-kyc-id .qr-wrapper p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 20px;
}

.corner-button {
  display: inline-block;
  position: relative;
  background: none;
  color: #000;
  box-sizing: content-box;
  border: 2px solid transparent;
  text-align: center;
  transition: 0.5s color;
}

.corner-button:before {
  top: -2px;
  left: -2px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transition: 0.5s all;
}

.corner-button:after {
  top: -2px;
  right: -2px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transition: 0.5s all;
}

.corner-button:before,
.corner-button:after,
.corner-button .corner-bottom-button:before,
.corner-button .corner-bottom-button:after {
  display: block;
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
}

.corner-button .corner-bottom-button {
  display: block;
}

.corner-button .corner-bottom-button:before {
  bottom: -2px;
  left: -2px;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transition: 0.5s all;
}

.corner-button .corner-bottom-button:after {
  bottom: -2px;
  right: -2px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  transition: 0.5s all;
}

.kyc-btn-grid .popup-btn-grid {
  margin-bottom: 40px;
}