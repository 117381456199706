.admin-section-title{
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.admin-section-title h3{
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
}

.admin-tab-section ul.nav.nav-tabs {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.admin-tab-section .nav-tabs .nav-link {
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-transform: uppercase;
    color: #675C7B;
    border: none;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;

}

.admin-tab-section .nav-tabs li {
    margin-right: 40px;
}

.admin-tab-section .nav-tabs li:last-child {
    margin: 0;
}

.admin-tab-section .nav-tabs .nav-item.show .nav-link,
.admin-tab-section .nav-tabs .nav-link.active {
    color: #E80054;
    background: none;
    border-bottom: 2px solid #E80054;
}


.admin-game-table{
    padding-top: 41px;
}
.admin-game-table .table-responsive {
    padding-top: 30px;
    margin: 0;
}
.admin-game-table .table{
    margin: 0;
}
.admin-game-table .table tbody tr{
    background: rgba(66, 85, 183, 0.1);
    border: 1px solid rgba(66, 85, 183, 0.5);
    box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 10px;
}
.admin-game-table .table tbody tr:hover{
    background: rgba(66, 85, 183, 0.2);
}
.admin-game-table .table tbody td {
    border: none;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.003em;
    padding: 10px;
    border-radius: unset;
    border-bottom: none;
    vertical-align: middle;
}
.admin-custom-table {
    padding-top: 30px;
}

.admin-custom-row {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: rgba(66, 85, 183, 0.1);
    border: 1px solid rgba(66, 85, 183, 0.5);
    box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px 20px 10px 10px;
}
.admin-custom-row:hover{
    background: rgba(66, 85, 183, 0.2);
}
.admin-custom-row:last-child{
    margin: 0;
}


.game-name-grid{
    display: flex;
    align-items: center;
    gap: 17px;
    max-width: 300px;
    width: 100%;
}
.game-name-grid img{
    box-shadow: 0px 14px 50px -6px rgba(18, 15, 39, 0.4);
    border-radius: 7px;
    max-width: 82px;
    width: 100%;
    height: 60px;
}
.game-name-box h6{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
    letter-spacing: unset;
}
.game-name-box p{
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: rgb(255 255 255 / 60%);
    margin: 0;
}
.user-name-grid{
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 290px;
    width: 100%;
}
.user-name-grid img{
    border-radius: 100%;
    max-width: 50px;
    width: 100%;
    height: 50px;
}
.user-name-box h6{
    margin: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
}
.user-name-box p{
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.003em;
    color: rgb(255 255 255 / 60%);
}
.action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    max-width: 226px;
    width: 100%;
}
.single-action-btn{
    max-width: 120px;
    width: 100%;
}
.cancle-btn{
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid rgb(255 255 255 / 20%);
    background: transparent;
    max-width: 103px;
    width: 100%;
    padding: 9px;
}
.cancle-btn:hover, .cancle-btn:focus{
    background: transparent;
    border-color: rgb(255 255 255 / 20%);
    box-shadow: none;
}
.confirm-btn{
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    max-width: 103px;
    width: 100%;
    padding: 9px;
}
.confirm-btn:hover, .confirm-btn:focus{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: none;
}
.request-status-grid{
    display: flex;
    max-width: 80px;
    width: 100%;
}
.request-status h6{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    margin: 0;
}
.request-status h6.confirm{
    color: #58C581;
}
.request-status h6.cancel{
    color: #E80054;
}
.request-status p{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    margin: 0;
}
.admin-game-confirm .confirm-btn{
    max-width: 120px;
    padding: 10px;
}
.admin-game-cancle .confirm-btn{
    max-width: 120px;
    padding: 10px;
}