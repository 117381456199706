.transaction-image img {
  max-width: 70px;
  height: 16px;
}

.green-status-text span {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: #58c581;
  position: relative;
  padding-left: 7px;
}

.green-status-text span:before {
  content: "";
  position: absolute;
  background: #58c581;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  top: 6px;
  left: 0;
}

.red-status-text span {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: #e80054;
  position: relative;
  padding-left: 7px;
}

.red-status-text span:before {
  content: "";
  position: absolute;
  background: #e80054;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  top: 6px;
  left: 0;
}

.grey-status-text span {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: #808080;
  position: relative;
  padding-left: 7px;
}

.grey-status-text span:before {
  content: "";
  position: absolute;
  background: #808080;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  top: 6px;
  left: 0;
}

.transaction-coins {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}

.transaction-coins img {
  width: 12px;
  height: 12px;
}

.approved-game .my-game-page.wallet-user-recent-game-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding-top: 30px;
}

.deposit-page .cancle-btn {
  max-width: 120px;
}

.lastlogin-table .table thead th:last-child,
.lastlogin-table .table tbody td:last-child {
  text-align: center;
}

.admin-table.lastlogin-table tr td {
  width: 50%;
}

/* ---------------------------------- */

.wallet-info-section {
  box-shadow: 0 14px 50px -6px rgb(18 15 39 / 40%);
  padding: 15px;
  margin-top: 10px;
  background: #1b1237;
  border-radius: 4px;
}

.wallet-info-section h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.wallet-info-section {
  box-shadow: 0 14px 50px -6px rgb(18 15 39 / 40%);
  padding: 30px 50px 30px 50px;
  margin-top: 10px;
  background: #1b1237;
  border-radius: 4px;
}

.wallet-setting label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding-bottom: 10px;
}

.wallet-setting .form-control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 48px !important;
  color: #ffffff;
  height: 50px;
  width: 100%;
  margin-bottom: 13px;
  padding: .499rem .75rem .375rem .75rem;
}



.emaipassword-box {
  box-shadow: 0 14px 50px -6px rgb(18 15 39 / 40%);
  padding: 15px;
  margin-top: 10px;
  background: #1b1237;
  border-radius: 4px;
}

.email-info {
  padding: 30px 50px 0 40px;
  border-right: 1px solid #2a1e52;
}

.email-info h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.email-setting label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding-bottom: 10px;
}

.email-setting .form-control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 48px !important;
  color: #ffffff;
  height: 50px;
  width: 100%;
  margin-bottom: 13px;
  padding: .499rem .75rem .375rem .75rem;
}


.email-password-info {
  padding: 30px 50px 0 40px;
}

.email-password-info h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.email-setting-password label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding-bottom: 10px;
}

.email-setting-input .form-control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 48px !important;
  color: #ffffff;
  height: 50px;
  width: 100%;
  margin-bottom: 13px;
  padding: .499rem .75rem .375rem .75rem;
}

.emaipassword-box .btn-primary {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  background: #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 10px;
  border: none;
  width: 159px;
  height: 47px;
}

.email-setting-input {
  position: relative;
}

.email-setting-input img {
  position: absolute;
  top: 20px;
  right: 19px;
  cursor: pointer;
}

.wallet-setting .btn-primary {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  background: #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 10px;
  border: none;
  width: 159px;
  height: 47px;
}


.input-pass-icon {
  position: absolute;
  right: 70px;
  bottom: 160px;
  color: #2E8BE6;
  cursor: pointer;
}

.input-cpass-icon {
  position: absolute;
  right: 18px;
  top: 20px;
  color: #2E8BE6;
  cursor: pointer;
}

.nav-tabs .nav-link {
  color: black;

  &:hover {
    border: none;
  }

  &:focus-visible {
    border: none;
  }


}

.tabs-container .nav-tabs {
  display: flex;
  justify-content: space-evenly;
  border: none;
}

.nav-item {
  border: none !important;


}

.nav-link.active {

  color: #e80054 !important
}

.input-style {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
  padding: 15px 20px;
  height: 60px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #e80054;
  border-top: none;
  border-left: none;
  border-right: none;

}

.btn-footer .btn-primary {
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border: none;
}

.btn-cancel {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #0e0d27;

  &:hover {
    background-color: transparent;
    color: #0e0d27;
    border: 1px solid rgb(14 13 39 / 20%);
  }
}

.input-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #0e0d27;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.recent-game_box_img img {
  /* max-width: 300px; */
  width: 100%;
  height: 260px;
  box-shadow: 0px 14px 50px -6px rgba(18, 15, 39, 0.4);
  border-radius: 10px;
}

.footer-btns button {
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  border: none;
  border-radius: 8px;
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 25%);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 10px;
  text-align: center;
  margin-right: 5px;
}