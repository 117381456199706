.forget-form {
  margin: 0px auto;
  padding: 0px 0px;
  position: relative;
}
.forget-form img {
  max-width: 80px;
  margin: 0px auto;
  display: block;
}

.forget-form h1 {
  font-size: 32px;
  text-align: center;
  color: #fff;
}

.forget-form .form-group .form-control {
  height: auto;
  padding: 5px 15px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}

.forget-form .form-group .form-control::placeholder {
  color: #ffffff;
}

.forget-form .form-group .form-control:focus {
  box-shadow: none;
  background: #31343c66;
}

.forget-form p {
  text-align: center;
  color: #aca2b4;
  max-width: 468px;
  margin: 0px auto;
}

.forget-box {
  max-width: 420px;
  margin: 0px auto;
  padding-top: 40px;
}

.forget-form .reset-btn {
  background: #025b95;
  border: none;
  transition: all 0.5s;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 30px;
  margin: 0px auto;
  display: block;
}

.reset-box {
  max-width: 450px;
  margin: 50px auto;
  margin-bottom: 0;
}

.forget-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.forget-form .form-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

.send-btn,
.send-btn:hover {
  background: #7c748e !important;
  border-radius: 2px !important;
  border: none !important;
  padding: 4px 12px !important;
  position: absolute;
  right: 5px;
  top: 38px;
}
.send-btn:focus,
.send-btn .btn:focus {
  box-shadow: none;
}
.forget-form .l-btn.btn.btn-primary {
  margin-top: 20px;
}
.reset-password-section .input-icon {
  color: #7c748e;
}
.reset-password-section h1 {
  padding-bottom: 25px;
}
.error-field.form-control {
  border-color: #ff6c01 !important;
  background: rgba(255, 108, 1, 0.1) !important;
  border-radius: 2px !important;
}
