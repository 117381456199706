.auth-page{
  background: url("../../assets/images/login/login-bg.png") #0E0D27;
  width: 100%;
  background-size: cover;
  height: 100vh;
  padding-top: 75px;
  padding-bottom: 128px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-page h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  margin: 0;
  padding-bottom: 10px;
}

.auth-page p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: rgb(172 162 180 / 80%);
  margin: 0;
  padding-bottom: 25px;
  position: relative;
}
.auth-para, .reset-password-section h1{
  position: relative;
}
.auth-para:after, .reset-password-section h1:after{
  content: "";
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 51.42%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  max-width: 594px;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 50%;
  opacity: 0.3;
  transform: translate( -50%, 0);
}
.error-text {
  text-align: left!important;
  max-width: 100%!important;
  font-size: 10px!important;
  font-weight: 400!important;
  padding: 5px 0px 0px!important;
  margin: 0!important;
  border: none !important;
  line-height: 10px !important;
  color: #FF6C01 !important;
}
.error-field.form-control {
  border-color: #FF6C01!important;
  background: rgba(255, 108, 1, 0.1) !important;
  border-radius: 2px !important;
}
p.already {
  padding: 0;
}
.login-form {
  margin: 0px auto;
  padding: 0px 0px;
  position: relative;
}

.login-form img {
  max-width: 80px;
  width: 100%;
  margin: 0px auto;
  display: block;
}
.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid#018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}
.input-icon{
  position: absolute;
  right: 20px;
  top: 44px;
  color: #2E8BE6;
  cursor: pointer;
}

.login-box {
  padding-top: 40px;
  max-width: 420px;
  margin: 0px auto;
}
.login-box .form-label{
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.login-box .form-group .form-control {
  height: auto;
  padding: 5px 15px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7C748E;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
}

.login-box .form-group .form-control::placeholder {
  color: #FFFFFF;
}

.login-box .form-group .form-control:focus {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.05);
}

.login-box input:-internal-autofill-selected {
  background: rgba(255, 255, 255, 0.05) !important;
}
.login-box .form-group .form-control:-webkit-autofill{
  box-shadow: none;
}

.login-box .form-group {
  margin-bottom: 20px;
  position: relative;
}
.login-box .form-group .input-icon{
  color: #7C748E;
}

.l-btn.btn.btn-primary {
  padding: 9px 40px;
  background: #E80054;
  box-shadow: 0px 19px 30px -5px rgb(232 0 84 / 25%);
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #FFFFFF;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 40px;
}

.l-btn.btn.btn-primary:hover,
.l-btn.btn.btn-primary:focus,
.l-btn.btn.btn-primary:active {
  background: #E80054;
  box-shadow: none;
  border: none;
}

.login-button {
  text-align: center;
}

.login-box p {
  text-align: center;
  padding-top: 20px;
  font-size: 14px;
  color: #99a4b0cc;
  margin: 0;
}

.signup-link {
  color: #E80054;
  text-decoration: underline;
}

.signup-link:hover {
  color: #c5c5c5;
}

.forget-link {
  color: #E80054;
  font-size: 13px;
}

.forget-link:hover {
  color: #99a4b0cc;
  text-decoration: none;
}

.forget-password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.forget-password a{
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #FFFFFF;
  text-decoration: none;
}
.forget-password a:hover{
  color: #E80054;
}
.remember-me .form-check-input[type=checkbox]{
  width: 20px;
  height: 20px;
  border: 1px solid #79748E;
  border-radius: 4px;
  background: transparent;
}

.remember-me .form-check-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: rgb(255 255 255 / 80%);
  padding-left: 10px;
}
.remember-me .form-check-input:focus{
  box-shadow: none;
}
.remember-me  .form-check-input:checked[type=checkbox]{
  background: url("../../assets/images/header/checkTick.svg");
  width: 20px;
  height: 20px;
  background-position: center;
  border: none;
}

.social-btns .btn {
  width: 48%;
  font-size: 14px;
}

.login {
  position: relative;
}

.login-popup .form-label {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px;
}

.forget-link,
.forget-link:focus,
.forget-link:hover {
  background: transparent !important;
  border: none !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #E80054 !important;
  box-shadow: none !important;
  padding: 0 !important;
  letter-spacing: 0.003em;
  padding-top: 20px !important;
  display: inline-block;
}

.forget-link.btn-primary:not(:disabled):not(.disabled).active:focus,
.forget-link.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important;
}
