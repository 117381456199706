.admin-games-content {
  padding-top: 30px;
}

.admin-games,
.admin-brands {
  padding-top: 30px;
}

.new-game-btn-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.admin-section-title button,
.new-game-btn {
  background: #e80054;
  box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.003em;
  color: #ffffff;
  height: 60px;
  gap: 10px;
  max-width: 255px;
  width: 100%;
  border: none;
  padding: 20px;
}

.admin-section-title button:hover,
.new-game-btn:hover {
  background: #e80054;
  border: none;
}

.admin-section-title button:focus,
.new-game-btn:focus,
.admin-section-title .btn:active:focus,
.new-game-btn.btn:active:focus {
  background: #e80054;
  border: none;
  box-shadow: none;
}

.admin-games-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 30px;
}

.waiting-game-content .recent-game-detail-bottom {
  align-items: flex-end;
}

.recent-game-detail-right p {
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  text-align: right;
  color: rgb(255 255 255 / 60%);
  margin: 0;
  padding-bottom: 4px;
}

.recent-game-detail-left p {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}

.recent-game-detail-img span {
  color: #2e8be6;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  padding-left: 5px;
}

.recent-game-detail-right button.red-btn {
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  border: none;
  border-radius: 8px;
  box-shadow: 0 19px 30px -5px rgb(232 0 84 / 25%);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 10px;
  text-align: center;
  margin-right: 5px;
  width: 50px;
}

.admin-games-box {
  position: relative;
}

.admin-games-image img {
  border-radius: 10px;
  box-shadow: 0 14px 50px -6px rgb(18 15 39 / 40%);
  height: 260px;
  max-width: 255px;
  width: 100%;
}

.recent-game-detail-img img {
  height: 9px;
  max-width: 12px;
}

.recent-game-detail {
  background: #161130e6;
  border-radius: 10px;
  bottom: 0;
  margin: 10px;
  max-width: 235px;
  position: absolute;
  width: 100%;
}

.recent-game-detail-bottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.game-info-popup .modal-content {
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 590px;
  margin: 0 auto;
  border: none;
}

.game-info-popup .modal-header {
  border: none;
  padding: 0px;
}

.game-info-popup .btn-close {
  background: url("../../assets//images//setting/close-white.svg");
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 40px;
  top: 40px;
}

.game-info-popup .modal-body {
  padding: 40px 60px;
}

.game-info-banner {
  position: relative;
  width: 100%;
}

.game-info-banner img {
  height: 300px;
  width: 100%;
  border-radius: 10px;
}

.game-info-name {
  position: absolute;
  top: 0;
  background: rgb(14 13 39 / 70%);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 100%;
  padding: 40px 40px 40px 60px;
}

.game-info-name h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
}

.game-info-wrapper h6 {
  font-weight: 700;
  font-size: 32px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
  text-align: center;
}

.game-info-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px dashed rgb(14 13 39 / 10%);
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.game-info-left p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #0e0d27;
  margin: 0;
}

.game-info-right p {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: #2e8be6;
  text-align: right;
  margin: 0;
}

.account-info-content {
  margin-top: 25px;
}

.popup-footer-btn {
  display: flex;
  padding-top: 40px;
  justify-content: center;
}

.popup-black {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  max-width: 220px;
  width: 100%;
  padding: 16.5px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #0e0d27;
}

.popup-black:hover {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  color: #0e0d27;
}

.popup-black:focus,
.popup-black.btn:active:focus {
  box-shadow: none;
  color: #0e0d27;
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
}

.popup-red {
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  max-width: 220px;
  width: 100%;
  border: none;
  padding: 16.5px 0;
}

.popup-red:focus,
.popup-red.btn:active:focus {
  box-shadow: none;
}

.game-request-popup .modal-fullscreen {
  margin: 40px auto;
  width: 66vw;
}

.game-request-popup .modal-content {
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 850px;
  margin: 0 auto;
  border: none;
}

.game-request-popup .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}

.game-request-popup .btn-close {
  /* background: url("../../assets/images/offer/close-icon.svg") center; */
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
}

.game-request-popup .btn-close:focus {
  box-shadow: none;
}

.game-request-popup .modal-body {
  padding: 0px;
  color: #0e0d27;
}

.game-request-heading h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 5px;
}

.game-request-heading p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgb(14 13 39 / 60%);
  margin: 0;
}

.new-game-wrapper form {
  padding: 0px 40px 60px 40px;
}

.new-game-wrapper .form-group {
  margin-bottom: 20px;
}

.new-game-wrapper label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #0e0d27;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.new-game-wrapper .form-control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
  padding: 15px 20px;
  height: 44px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
}

.new-game-wrapper .form-control:focus {
  box-shadow: none;
}

.new-game-wrapper textarea.form-control {
  font-weight: 400 !important;
  height: 150px;
}

.new-game-wrapper .form-control::placeholder {
  color: #0e0d27;
}

.brand-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.brand-input .form-group {
  max-width: 48%;
  width: 100%;
}

.upload-game-images .form-control {
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
}

.upload-game-banner {
  margin: 0;
  background: #f3f3f4;
  border: 1px dashed #0e0d27;
  border-radius: 10px;
  cursor: pointer;
  height: 250px;
  width: 100%;
  text-align: center;
  padding: 5px;
  overflow: hidden;
}

.upload-game-thumnail label {
  margin: 0;
  background: #f3f3f4;
  border: 1px dashed #0e0d27;
  border-radius: 10px;
  cursor: pointer;
  height: 250px;
  max-width: 250px;
  width: 100%;
  text-align: center;
}

.upload-game-thumnail label img {
  height: 250px;
  max-width: 250px;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.no-image-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 245px;
  padding: 8px 30px;
  position: relative;
  gap: 8px;
  overflow: auto;
}

.no-image-area label {
  margin: 0;
}

.no-image-area::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.no-image-area::-webkit-scrollbar-track {
  background: rgb(170 170 170 / 20%);
}

.no-image-area::-webkit-scrollbar-thumb {
  background-color: #E80054;
}

.no-image-area .preview-banner {
  position: relative;
  max-width: 150px;
  width: 100%;
  height: 110px;
}

.no-image-area .crossImg {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.8;
  height: auto;
}

.no-image-area .crossImg:hover {
  opacity: 1;
}

.no-image-area .previewImg {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
}

.no-image-area h6 {
  font-size: 14px;
  margin: 0;
  line-height: 20px;
}

.no-image-area h6 span {
  text-decoration: underline;
}

span.add-more {
  cursor: pointer;
  border: 1px dashed #0e0d27;
  border-radius: 10px;
  max-width: 100px;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  padding: 5px;
}

span.add-more .las {
  color: #0e0d27;
  font-size: 16px;
}

.upload-logo-thumnail {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.upload-logo-thumnail .no-image-area {
  overflow: unset;
  height: 250px;
  padding: 0px 30px;
}

.process-popup.modal-content {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 850px;
  margin: 0 auto;
}

.process-popup .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}

.process-popup .btn-close {
  /* background: url("../../../assets//images/offer/close-icon.svg") center; */
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
}

.process-popup .modal-body {
  padding: 0px 40px 60px 40px;
  text-align: center;
}

.game-request-popup .popup-footer-btn {
  padding-top: 30px;
  justify-content: center;
  gap: 30px;
}

.game-request-popup .popup-black,
.game-request-popup .popup-red {
  max-width: 270px;
}