.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background: #121338;
  min-height: 70px;
  filter: drop-shadow(0px 34px 60px rgba(25, 11, 39, 0.25));
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99;
}
.brand-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;
  text-decoration: none;
  max-width: 300px;
  width: 100%;
}
.brand-name:hover {
  color: #e80054;
}
.brand-name img {
  max-width: 40px;
  width: 100%;
  height: 40px;
  margin-right: 20px;
}
.user-loggedin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 240px;
  width: 100%;
  position: relative;
}
.notification-btn {
  cursor: pointer;
  position: relative;
}
.notification-btn h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}
.red-icon {
  background: #e80054;
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 100%;
  top: 8px;
  right: -8px;
}
span.wallet-section {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}
span.wallet-section img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.user-pic .btn-primary {
  background: transparent;
  border: none;
  gap: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0;
}

.user-pic .btn-check:active + .btn:focus,
.user-pic .btn-check:checked + .btn:focus,
.user-pic .btn.active:focus,
.user-pic .btn.show:focus,
.user-pic .btn:active:focus,
.user-pic .btn:hover,
.user-pic .btn:active,
.user-pic .btn:focus {
  box-shadow: none;
  background: transparent;
}

.user-pic .btn-primary img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
}
.user-pic .dropdown-toggle::after {
  background: url("../../assets/images/header/profile-arrow.svg");
  position: absolute;
  width: 8px;
  height: 8px;
  right: -15px;
  border: 0;
  margin: 0;
  display: none;
}

.user-pic .dropdown-menu {
  padding: 10px 0px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #e70054;
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin-top: 20px;
  overflow-y: auto;
  display: none;
}

.user-pic .dropdown-item {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  letter-spacing: 0.003em;
  padding: 5px 15px;
}

.user-pic .dropdown-item:focus,
.user-pic .dropdown-item:hover {
  background: rgb(206 2 80 / 30%);
  color: #ffffff;
}
.header-menu {
  display: none;
}
.header-menu .navbar-expand-lg .navbar-nav .nav-link:hover {
  border-color: #e80054;
  border-radius: 10px;
  color: #ffffff;
}
.header-menu .navbar-toggler,
.header-menu .navbar-toggler:focus {
  background: transparent;
  box-shadow: none;
}
.header-menu .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("../../assets/images/header/menu.svg");
  width: 40px;
  transition: all 0.5s;
}
.header-menu .navbar-toggler .navbar-toggler-icon {
  background-image: url("../../assets/images/header/cross-menu.svg");
  width: 25px;
}

/* Notification CSS */

.notification-wrapper.offcanvas {
  background: rgba(255, 255, 255, 0.8);
  background: #d3d3dc;
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  padding: 30px 30px 20px 30px;
  max-width: 570px;
  top: 76px;
  transition: all 0.3s ease 0s;
  width: 100%;
  z-index: 999999;
}
.notification-wrapper.offcanvas.offcanvas-end:after {
  bottom: 100%;
  left: 52%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #d6d4dc;
  border-width: 10px;
}
.offcanvas-backdrop.show {
  background: transparent;
}
.notification-list {
  height: calc(100vh - 180px);
  overflow-y: auto;
  padding-right: 20px;
}
.notification-list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.notification-list::-webkit-scrollbar-track {
  background: rgb(170 170 170 / 20%);
}

.notification-list::-webkit-scrollbar-thumb {
  background-color: #e80054;
}
.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 20px;
}
.notification-header h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
}
.notification-header span {
  font-weight: 400;
  font-size: 10px;
  line-height: 30px;
  text-align: right;
  letter-spacing: 0.003em;
  color: rgb(14 13 39 / 40%);
  cursor: pointer;
}
.notfication-card {
  background: #ffffff;
  border-radius: 9px 6px 6px 6px;
  margin-bottom: 10px;
}
.notfication-card-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 12px 15px;
  border-bottom: 1px solid rgb(14 13 39 / 10%);
}
.notfication-card-header h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
}
.notfication-card-header .form-check-input {
  background: rgb(14 13 39 / 5%);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin: 0;
}
.notfication-card-header .form-check-input:focus {
  box-shadow: none;
}
.notfication-card-header .form-check-input:checked[type="checkbox"] {
  background: url("../../assets//images//header/tick.svg");
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: none;
  background-position: center;
}
.notfication-card-body {
  padding: 15px;
}
.game-request-body {
  display: flex;
  gap: 50px;
  align-items: center;
}
.notification-game-grid {
  display: flex;
  align-items: center;
  gap: 10px;
}
.notification-game-image img {
  width: 68px;
  height: 50px;
  border-radius: 7px;
}
.notification-game-name h6 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
}
.notification-game-name p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgb(14 13 39 / 60%);
  margin: 0;
}
.transaction-body-grid {
  display: flex;
  gap: 32px;
}
.transaction-via img {
  width: 131px;
  height: 30px;
}
.transaction-via {
  padding-right: 10px;
}
.transaction-type p,
.transaction-amount p {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
  color: rgb(14 13 39 / 60%);
  margin: 0;
}
.transaction-type h6,
.transaction-amount h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0e0d27;
  margin: 0;
}
.transaction-amount h6 {
  font-weight: 600;
}
.transaction-amount img {
  width: 15px;
  height: 15px;
}
.distributor-request-body {
  display: flex;
  justify-content: space-between;
}
.distributor-request-box {
  width: calc(100% / 3);
}
.distributor-request-box p {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
  color: rgb(14 13 39 / 60%);
  margin: 0;
}
.distributor-request-box h6 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
}
.notfication-card-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 15px;
}
.notfication-card-footer h6 {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: rgb(14 13 39 / 50%);
  margin: 0;
}
.notfication-card-footer a {
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  text-align: right;
  text-transform: uppercase;
  color: #2e8be6;
  text-decoration: none;
}
.notfication-card-footer a:hover {
  text-decoration: underline;
}

.animated-logo img {
  max-width: 80px;
  height: 80px;
}
