.admin-table .table-responsive {
  padding: 1px;
  background: rgb(255 255 255 / 10%);
  border-radius: 10px;
  margin: 0;
}
.admin-table .table {
  margin: 0;
}
.admin-table .table > thead {
  background: rgb(22 17 48 / 90%);
  border-radius: 9px 9px 0px 0px;
}

.admin-table .table thead th {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(255 255 255 / 50%);
  border: none;
  padding: 18px 10px 21px 30px;
}
.admin-table .table thead th:first-child {
  border-radius: 9px 0 0;
}
.admin-table .table thead th:last-child {
  border-radius: 0 9px 0 0;
}
.admin-table .table tbody tr:hover {
  background: rgb(46 139 230 / 10%);
}
.admin-table .table tbody td {
  border: none;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  padding: 10px 10px 10px 30px;
  white-space: nowrap;
  border-bottom: 1px solid #1f1842;
  vertical-align: middle;
}

.admin-table .table tbody tr:last-child td {
  border-bottom: none;
}
.user-name {
  font-weight: 700;
}
.user-name img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
}
.draft-balance {
  display: flex;
  align-items: center;
}
.draft-balance img {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-right: 5px;
}
.total-balance {
  font-weight: 700;
}
.total-balance img {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 5px;
}
