.deposit-page .confirm-btn {
    max-width: 120px;
}

.deposit-page .transaction-amt h6 {
    font-size: 12px;
}

.deposit-page .transaction-amt-grid {
    max-width: 100px;
}

.coins-amt-grid {
    display: flex;
    max-width: 120px;
    width: 100%;
}

.coins-amt p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgb(255 255 255 / 60%);
    margin: 0;
}

.view-button {
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    background: linear-gradient(92.5deg, #1596d9 0.69%, #003ed6 101.34%);
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    max-width: 103px;
    width: 100%;
    padding: 9px;
}

.view-deposit-grids {
    /* padding: 5px 15px; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 13px;
    padding-right: 18px;
}

.coins-amt h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    gap: 5px;
    align-items: center;
}

.deposit-heading {
    padding: 20px 0;
    max-width: 580px;
    margin: 0px auto;
}

.deposit-heading h6 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #0e0d27;
    margin: 0;
}

.field-box {
    background: #f3f3f4;
    border-radius: 10px;
    padding: 20px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.field-box span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: rgb(14 13 39 / 50%);
    padding-left: 10px;
    width: calc(100% - 133px);
    overflow: hidden;
}

.upload-image-box {
    margin: 10px auto;
    width: 100%;
    padding: 20px;
    border: 2px dashed #c1c1c2;
    border-radius: 10px;
}

.preview img {
    height: 50px !important;
    width: 50px !important;
    cursor: pointer;
}

/* .upload-image-box img {
    max-width: 340px;
    width: 100%;
} */
.currency-type img {
    width: 90px;
}

.view-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;

}

.view-image img {
    max-width: "90%";
    max-height: "90%"
}

.payment-recipient {
    text-decoration: none;
    font-weight: normal;
    cursor: pointer;
}
.search-wrapper-deposit {
    position: relative;
    width: 100%;
    top: 100px;
  }