.admin-live-chat .admin-content-wrapper {
  padding: 30px 0 0;
}

.admin-live-chat .admin-section-title {
  padding-left: 30px;
}

.people-list {
  max-width: 380px;
  width: 100%;
  background: #21244E;
}

.people-list-header {
  padding: 30px;
}

.quick-request h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin: 0;
  padding-bottom: 30px;
}

.people-list-wrapper {
  padding-right: 15px;
}

.people-list ul {
  padding: 0 15px 0 30px;
  height: calc(100vh - 320px);
  overflow-y: auto;
  margin: 0;
}

.people-list ul::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.people-list ul::-webkit-scrollbar-track {
  background: rgb(170 170 170 / 20%);
}

.people-list ul::-webkit-scrollbar-thumb {
  background-color: #E80054;
}

.people-list ul li {
  padding: 15px;
  cursor: pointer;
  list-style: none;
  background: rgba(66, 85, 183, 0.1);
  border: 1px solid rgba(66, 85, 183, 0.5);
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}

.people-list ul li:hover {
  background: #4255B7;
  border: 1px solid #4255B7;
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
}

.people-list img {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  display: flex;
  gap: 10px;
}

.people-list .about .name {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  padding-bottom: 5px;
  text-transform: capitalize;
}

.people-list .about .email-about {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: rgb(255 255 255 / 60%);
}

.msg-time-grid {
  text-align: right;
}

.msg-time-box {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: right;
  color: rgb(255 255 255 / 20%);
}

.people-list .new-msg-count {
  position: absolute;
  right: 15px;
  bottom: 15px;
  background: #E80054;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 8px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
  border-radius: 50%;
}

.adminchat {
  max-width: calc(100% - 380px);
  width: 100%;
  float: left;
  background: #D3D1DA;
  color: #FFFFFF;
}

.adminchat .adminchat-header {
  padding: 20px 30px;
  background: rgb(255 255 255 / 80%);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adminchat-header-user {
  display: flex;
  gap: 20px;
  align-items: center;
}

.adminchat-header-user-info h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #16081A;
  margin: 0;
  text-transform: capitalize;
}

.adminchat-header-user-info p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(22 8 26 / 60%);
  margin: 0;
}

.adminchat .adminchat-header img {
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.adminchat-header-sub-info {
  max-width: 290px;
  width: 100%;
  display: flex;
  gap: 40px;
}

.adminchat-header-sub-info-box p {
  font-weight: 400;
  font-size: 8px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgb(22 8 26 / 50%);
  margin: 0;
}

.adminchat-header-sub-info-box h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #16081A;
  margin: 0;
}

.adminchat-header-sub-info-box h6.bold-text {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.adminchat-header-sub-info-box h6.bold-text img {
  width: 12px;
  height: 12px;
}

.adminchat .adminchat-history {
  padding: 30px;

}

.adminchat .adminchat-history ul {
  overflow-y: auto;
  height: calc(100vh - 442px);
  padding: 0;
  padding-right: 30px;
  list-style: none;
}

.adminchat .adminchat-history ul::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.adminchat .adminchat-history ul::-webkit-scrollbar-track {
  background: rgb(255 255 255 / 50%);
}

.adminchat .adminchat-history ul::-webkit-scrollbar-thumb {
  background-color: #E80054;
}

.adminchat .adminchat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}

.adminchat-message .form-control {
  background: rgb(255 255 255 / 80%);
  /* backdrop-filter: blur(15px); */
  border-radius: 10px;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  height: 60px;
  padding-left: 20px;
  padding-right: 10px;
  position: relative;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #16081A;
  padding-right: 115px;
}

.adminchat-message .form-control::placeholder {
  color: #16081A !important;
}

.admin-live-chat .admin-section-title button {
  display: none;
}

.mobile-user-list.offcanvas {
  background: #21244E;
  padding: 0;
  max-width: 300px;
  top: 86px;
  transition: all .3s ease 0s;
  width: 100%;
  z-index: 999999;
}

.adminchat .adminchat-history .my-message {
  background: #86bb71;
}

.adminchat .adminchat-history .other-message {
  background: #94c2ed;
}

.adminchat .adminchat-message {
  padding: 20px 30px;
  background: #C8C7D2;
  /* backdrop-filter: blur(15px); */
  position: relative;
  color: #16081A;
}

.adminchat .adminchat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  resize: none;
}

.adminchat .adminchat-message button {
  background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
  box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  min-width: 90px;
  height: 40px;
  position: absolute;
  right: 40px;
  top: 30px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix.active {
  background: #4255B7;
  border: 1px solid #4255B7;
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
}

.admin-support-adminchat-content {
  width: 100%;
  display: flex;
}

.user-message {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.admin-message-icon {
  width: 42px;
  height: 42px;
  border-radius: 34px;
  background: #E80054;
  text-align: center;
  line-height: 38px;
}

.user-message-icon img,
.admin-message-icon img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.admin-message {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.admin-message .message-box {
  background: #2E8BE6;
  border-radius: 10px 0px 10px 10px;
}

.admin-message .message-box h6 {
  color: #FFFFFF;
}

.admin-message .message-section p {
  text-align: left;
}

.message-section p {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: right;
  color: rgb(22 8 26 / 20%);
  margin: 0;
  padding-top: 10px;
}

.message-box {
  background: #FFFFFF;
  border-radius: 0px 10px 10px 10px;
  max-width: 400px;
  width: fit-content;
  padding: 20px;
}

.message-box h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #16081A;
  margin: 0;
  word-break: break-word;
  user-select: text;
}

.center_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.msg-timeline {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgb(22 8 26 / 30%);
  margin: 0;
  position: relative;
}

p.msg-timeline::before,
p.msg-timeline::after {
  position: absolute;
  content: "";
  background: rgb(22 8 26 / 5%);
  height: 1px;
  width: 40%;
  top: 10px;
}

p.msg-timeline::before {
  left: 0;
}

p.msg-timeline::after {
  right: 0;
}