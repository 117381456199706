.page-header-section {
  background: #21244e;
}
.page-header {
  padding: 30px 30px 40px;
}
.page-header-title h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}
.page-header-title p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: rgb(255 255 255 / 50%);
  margin: 0;
  padding-bottom: 20px;
}
.wallet-info {
  display: flex;
  gap: 60px;
  align-items: center;
  position: relative;
}
.total-coins p {
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;
}
.total-coins p img {
  width: 30px;
  height: 30px;
}
.coins-value-box p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}
.coins-value-box p span {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
  color: rgb(255 255 255 / 70%);
}

.page-header-title .user-data {
  display: flex;
}

.user-data h2 {
  margin: 5px;
}

.page-header-title img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

.recent-game_box img {
  max-width: 100%;
  height: 100%;
}
.recent-game_box img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-request-list .recent-game_box img {
  height: 140px;
}
.recent-game_box img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.recent-game_box img {
  height: 240px;
}

.recent-game_box img {
  max-width: 255px;
  width: 100%;
  height: 260px;
  box-shadow: 0px 14px 50px -6px rgba(18, 15, 39, 0.4);
  border-radius: 10px;
}

.recent-game_box {
  position: relative;
}

.player-profile-user-data {
  justify-content: space-between;
}
.sub-headings {
  display: flex;
  justify-content: space-between;
}

/* .last-login {
  position: absolute;
  right: 0px;
} */

.last-login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.last-login-wrapper p{
  padding-bottom: 0.5rem;
}

.registered-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wallet-info-wrapper p {
  color: #fff;
}