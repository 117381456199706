

body {
  font-family: 'Readex Pro'!important;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  font-weight: 400;
  overflow-x: hidden;
  color: #fff !important;
  background: #0E0D27 !important;
}

@font-face {
  font-family: "Readex Pro";
  src: url(./assets/fonts/ReadexPro-ExtraLight.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Readex Pro";
  src: url(./assets/fonts/ReadexPro-Light.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Readex Pro";
  src: url(./assets/fonts/ReadexPro-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Readex Pro";
  src: url(./assets/fonts/ReadexPro-Regular.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Readex Pro";
  src: url(./assets/fonts/ReadexPro-SemiBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Readex Pro";
  src: url(./assets/fonts/ReadexPro-bold.ttf);
  font-weight: 900;
}

.container{
  max-width: 1180px !important;
  padding: 0 !important;
}

.main-wrapper{
  position: relative;
}

/* .main-wrapper:before{
  content: "";
  background: url(./assets/images/crash/main-bg.png);
  background-attachment: fixed;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */

/* .main-wrapper:after {
  content: "";
  background: #24262b;
  background-attachment: fixed;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */


.loader{
  position: fixed;
  width: 100%;
  background: #1F1842;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}







/************************************************************************************************************/

                           /*************** Media Query ******************/

/************************************************************************************************************/

@media screen and (max-width:767px){

.container {
  padding: 0px 15px;
}

}