.delete-popup .modal-content{
    background: #FFFFFF;
    box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
    border-radius: 10px;
    max-width: 850px;
    margin: 0 auto;
}
.delete-popup .modal-header{
    border: none;
    padding: 40px 40px 0px;
    justify-content: flex-end;
}
.delete-popup .btn-close{
    background: url("../../assets//images/offer/close-icon.svg") center;
    width: 20px;
    height: 20px;
    opacity: unset;
    border-radius: unset;
    margin: 0;
    padding: 0;
}
.delete-popup .btn-close:focus {
    box-shadow: none;
}
.delete-popup .modal-body{
    padding: 0px;
}
.delete-popup-content{
    max-width: 577px;
    width: 100%;
    margin: 0px auto;
    text-align: center;
    padding-top: 20px;
}
.delete-popup-content h6{
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.003em;
    color: #0E0D27;
    margin: 0;
    padding-bottom: 10px;
}
.delete-popup-content p{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #0E0D27;
    opacity: 0.6;
    margin: 0;
}
.delete-popup-footer{
    padding: 30px 0;
    display: flex;
    justify-content: center;
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 80px;
}

.delete-popup-footer .btn-secondary{
    background: transparent;
    border: 1px solid rgb(14 13 39 / 20%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    max-width: 190px;
    width: 100%;
    padding: 16.5px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: #0E0D27;
}
.delete-popup-footer .btn-secondary:hover{
    background: transparent;
    border: 1px solid rgb(14 13 39 / 20%);
}
.delete-popup-footer .btn-secondary:focus{
    box-shadow: none;
}

.delete-popup-footer .btn-primary{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    max-width: 190px;
    width: 100%;
    border: none;
    padding: 16.5px 0;
}
.delete-popup-footer .btn-primary:focus{
    box-shadow: none;
}