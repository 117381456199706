.new-question-tab, .spam-question-tab, .answered-tab{
    padding-top: 30px;
}
.new-question-tab-content, .spam-question-tab-content, .answered-tab-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
}
.question-wrapper{
    background: rgba(66, 85, 183, 0.1);
    border: 1px solid rgba(66, 85, 183, 0.5);
    box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    padding: 30px;
    transition: all 0.5s;
}
.que-by-grid{
    display: flex;
    justify-content: space-between;
}
.que-by-grid span{
    cursor: pointer;
}
.question-by h6{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 10px;
}
.question-by a{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2D86E0;
}
.user-que-row{
    padding: 20px 0;
}
.user-que-row p{
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}
.quetion-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.quetion-time p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgb(255 255 255 / 20%);
    margin: 0;
}
.que-action-btn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    max-width: 220px;
    width: 100%;
}
.spam-btn{
    border-radius: 8px;
    border: 1px solid rgb(255 255 255 / 20%);
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    max-width: 100px;
    width: 100%;
    background: transparent;
    padding: 9px 5px;
}
.spam-btn:hover, .spam-btn:focus{
    background: transparent;
    border: 1px solid rgb(255 255 255 / 20%);
    color: #FFFFFF;
    box-shadow: none;
}
.send-mail-btn{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    max-width: 100px;
    width: 100%;
    border: none;
    padding: 9px 5px;
}
.send-mail-btn:hover, .send-mail-btn:focus{
    border: none;
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    color: #FFFFFF;
}

.answer-wrapper, .answer-wrapper-show{
    background: rgba(66, 85, 183, 0.3);
    border: 1px solid #4255B7;
    box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    padding: 30px;
    display: none;
    inset: 0px;
    z-index: 99;
    transition: all 0.5s;
}

.answer-wrapper-show {
    display: block;
}
.reply-box {
    width: 100%;
    position: relative;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgb(255 255 255 / 10%);
}
.reply-box-form {
    position: relative;
}

.reply-box .form-control {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    border: none;
    box-shadow: none;
    padding: 20px;
    padding-right: 110px;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: rgb(22 8 26 / 100%);
    height: 60px;
}

.reply-box button,
.reply-box button:hover,
.reply-box button:focus {
    border-color: transparent;
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    border-radius: 8px;
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 90px;
    height: 40px;
}
.reply-box button:hover, .reply-box button:focus, .reply-box button:active, .reply-box button:active:focus{
    border-color: transparent;
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
} 

.reply-box input::placeholder {
    color: rgb(22 8 26 / 100%);
}

.mark-spam{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #E80054;
}
.answered-wrapper {
    background: rgba(66, 85, 183, 0.1);
    border: 1px solid rgba(66, 85, 183, 0.5);
    box-shadow: -5px 17px 60px rgb(21 15 47 / 20%);
    backdrop-filter: blur(30px);
    border-radius: 10px;
   
}
.user-question-wrapper{
    padding: 30px;
}
.admin-answer-list-wrapper{
    background: rgba(66, 85, 183, 0.1);
    border-radius: 0px 0px 10px 10px;
    padding: 30px;
}
.admin-answer-list{
    padding-right: 20px;
    height: 275px;
    overflow: auto;
}
.admin-answer-list::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.admin-answer-list::-webkit-scrollbar-track {
    background:  rgb(255 255 255 / 10%);
    width: 1px;
}
  
.admin-answer-list::-webkit-scrollbar-thumb {
    background-color: #E80054;
 }

.admin-answer-wrapper {
    border-top: 1px solid rgb(255 255 255 / 10%);
    padding-top: 15px;
    margin-top: 15px;
}
.admin-answer-list .admin-answer-wrapper:first-child {
    border-top: none;
    padding-top: 0px;
    margin-top: 0px;
}
.another-mail-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}
.another-mail-btn{
    max-width: 150px;
    width: 100%;
    height: 40px;
    border: none;
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
.another-mail-btn:hover, .another-mail-btn:focus{
    border: none;
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    color: #FFFFFF;
}
.ans-by-grid {
    display: flex;
    align-items: center;
    gap: 15px;
}

.ans-by-img{
    background: #E80054;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    padding: 1px;
    text-align: center;
}

.ans-by-img img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.ans-by-heading h6 {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}
  

