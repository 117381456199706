@media screen and (max-width: 1400px) {
  .recent-game-detail {
    max-width: calc(100% - 20px);
  }
}

@media screen and (max-width: 1024px) {
  .login-box {
    padding-top: 30px;
  }

  .auth-page {
    padding-bottom: 75px;
  }

  .admin-table .table thead th {
    padding: 10px;
    white-space: nowrap;
  }

  .admin-table .table tbody td,
  .complete-distributor-tab .admin-table .table tbody td {
    padding: 10px;
  }

  .notification-wrapper.offcanvas {
    max-width: 400px;
    padding: 20px;
  }

  .notification-list {
    padding-right: 10px;
  }

  .notfication-card-body {
    padding: 12px;
  }

  .notfication-card-header {
    padding: 12px;
  }

  .notfication-card-footer {
    padding: 0 12px 12px;
  }

  span.wallet-section {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  span.wallet-section img {
    margin: 0;
  }

  .notification-wrapper.offcanvas.offcanvas-end:after {
    display: none;
  }

  .admin-tab-section .nav-tabs li {
    margin-right: 30px;
  }

  .admin-custom-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 15px;
  }

  .admin-custom-row {
    width: 1100px;
  }

  .game-request-page .admin-custom-row {
    width: 900px;
  }

  .game-recharge-page .admin-custom-row {
    width: 1000px;
  }

  .people-list {
    max-width: 220px;
  }

  .people-list-header {
    padding: 15px;
  }

  .people-list-wrapper {
    padding: 0 15px;
  }

  .people-list ul {
    height: calc(100vh - 284px);
    padding: 0;
    padding-right: 5px;
  }

  .people-list ul li {
    padding: 5px;
  }

  .adminchat .adminchat-history ul {
    height: calc(100vh - 368px);
    padding-right: 15px;
  }

  .quick-request h6 {
    padding-bottom: 15px;
  }

  .adminchat {
    max-width: calc(100% - 220px);
  }

  .adminchat .adminchat-header {
    padding: 15px 10px;
  }

  .adminchat-header-user {
    gap: 10px;
  }

  .adminchat .adminchat-header img {
    width: 40px;
    height: 40px;
  }

  .adminchat-header-user-info h6 {
    font-size: 14px;
  }

  .adminchat-header-user-info p {
    font-size: 12px;
  }

  .adminchat-header-sub-info {
    max-width: unset;
    width: unset;
    gap: 20px;
  }

  .adminchat .adminchat-history {
    padding: 15px;
  }

  .user-message,
  .admin-message {
    gap: 10px;
    margin-bottom: 10px;
  }

  .admin-message-icon {
    width: 27px;
    height: 27px;
    line-height: 26px;
  }

  .user-message-icon img,
  .admin-message-icon img {
    width: 25px;
    height: 25px;
  }

  .message-box {
    padding: 5px 10px;
    border-radius: 0px 5px 5px 5px;
  }

  .admin-message .message-box {
    border-radius: 5px 0px 5px 5px;
  }

  .message-section p {
    padding-top: 5px;
  }

  .admin-live-chat .admin-section-title {
    padding-left: 15px;
  }

  .adminchat .adminchat-message {
    padding: 15px;
  }

  .adminchat .adminchat-message button {
    top: 26px;
    right: 30px;
  }

  .distributor-left {
    padding: 15px;
    max-width: unset;
    width: 40%;
  }

  .distributor-right {
    padding: 15px;
    max-width: unset;
    width: 60%;
  }

  .simple-offer-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }

  .normal-offer-box {
    display: flex;
    min-height: 355px;
    flex-direction: column;
    justify-content: space-between;
  }

  .normal-offer-box img {
    height: 200px;
  }

  .inner-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .admin-games-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .admin-section-title button,
  .new-game-btn {
    font-size: 16px;
    height: 44px;
    max-width: 200px;
  }

  .admin-games-image img {
    height: 200px;
  }

  .recent-game-detail {
    max-width: 126px;
  }

  .recent-game-detail-bottom {
    padding: 10px;
  }

  .edit-offer-menu {
    top: 10px;
    right: 10px;
  }

  /* *************************  Setting Page ************************ */
  .setting-page-detail {
    flex-wrap: wrap;
    width: 100%;
  }

  .setting-page-detail-left {
    width: 100%;
  }

  .setting-profile {
    margin: 0 auto;
  }

  .setting-page-detail-right {
    max-width: 100%;
    padding-top: 50px;
  }

  .setting-qr-code p {
    line-height: 20px;
  }

  /*************************** Withdraw Popup *****************************/
  .withdarw-popup .modal-header {
    padding: 20px 20px 0;
  }

  .withdarw-popup .modal-content {
    max-width: 600px;
  }

  .transaction-body-content img {
    width: 180px;
  }

  .transaction-heading {
    padding: 30px 0;
  }

  .transaction-heading h6 {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 20px;
    max-width: 370px;
    width: 100%;
    margin: 0 auto;
  }

  .transaction-heading p {
    font-size: 32px;
    line-height: 32px;
  }

  span.link-icon img {
    width: 16px;
  }

  .withdarw-popup .modal-body {
    padding-bottom: 40px;
  }

  .qr-box {
    max-width: fit-content;
  }

  /*************************** offer Popup *****************************/

  .normal-offer-step2 .modal-header {
    padding: 20px;
  }

  .normal-offer-form-2 {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .normal-offer-form-right {
    max-width: unset;
    padding: 20px;
  }

  .normal-offer-form-left {
    max-width: unset;
    width: 100%;
    padding: 20px;
    border-right: unset;
  }

  .area-title {
    padding-bottom: 20px;
  }

  .bg-color-head {
    width: 100%;
  }

  .bg-color-footer {
    width: 100%;
  }

  .normal-offer-step2 .modal-content {
    max-width: 600px;
  }

  .normal-offer-step2 .modal-title.h4 {
    font-size: 24px;
  }

  .normal-offer-form-right .normal-offer-box {
    margin: 0 auto;
  }

  .normal-offer-form-right.horizontal-image {
    padding: 0px 85px;
  }

  /*************************** create offer Popup *****************************/

  .normal-offer-step1 .modal-content {
    max-width: 600px;
  }

  .normal-offer-step1 .modal-header {
    padding: 20px 20px 0;
  }

  .normal-offer-step1 .modal-body h5 {
    font-size: 24px;
    padding-bottom: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 991px) {
  .admin-wrapper .admin-menu {
    display: none;
  }

  .admin-container {
    max-width: 100%;
    margin-left: 0;
  }

  .admin-menu {
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    max-width: 290px;
  }

  .header-menu {
    max-width: 56px;
    width: 100%;
  }

  .header-menu #responsive-navbar-nav {
    background: transparent;
    box-shadow: 0 4px 31px rgb(35 40 59 / 10%);
    height: 100%;
    left: -315px;
    max-width: 315px;
    padding: 0;
    position: fixed;
    top: 0;
    transition: all 0.3s ease 0s;
    width: 100%;
    z-index: 999999;
  }

  .header-menu .navbar-collapse.collapse.show {
    left: 0 !important;
  }

  .header-menu {
    display: block;
  }

  .user-loggedin {
    justify-content: flex-end;
    max-width: 520px;
    gap: 30px;
  }

  .admin-games-image img {
    max-width: 100%;
  }

  .admin-games-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .recent-game-detail {
    max-width: calc(100% - 20px);
  }

  .game-request-popup .modal-header {
    padding: 20px 20px 0px;
  }

  .game-request-popup .btn-close {
    width: 12px;
    height: 12px;
  }

  .new-game-wrapper form {
    padding: 0px 30px 30px 30px;
  }

  .game-request-heading h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .new-game-wrapper .form-group {
    margin-bottom: 10px;
  }

  .new-game-wrapper label {
    margin-bottom: 0px;
  }

  .new-game-wrapper .form-control {
    height: 38px;
    font-size: 14px;
    padding: 10px;
    font-weight: 500;
  }

  .new-game-wrapper textarea.form-control {
    height: 100px;
  }

  .brand-input .form-group {
    max-width: 100%;
  }

  .upload-game-images .form-control {
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  }

  .upload-game-banner {
    height: 200px;
  }

  .no-image-area {
    height: 195px;
  }

  .upload-logo-thumnail {
    gap: 15px;
  }

  .upload-game-thumnail label {
    height: 180px;
    max-width: 140px;
  }

  .upload-game-thumnail label .no-image-area {
    height: 180px;
  }

  .upload-game-thumnail label .no-image-area h6 {
    font-size: 11px;
  }

  .game-request-popup .popup-black,
  .game-request-popup .popup-red,
  .popup-cancel-btn,
  .popup-confirm-btn {
    max-width: 100px;
    padding: 6.5px 0;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .popup-black {
    max-width: 150px;
    padding: 11.5px 0;
    border-radius: 10px;
    font-size: 16px;
  }

  .offer-tab,
  .special-deal-tab {
    padding-top: 0;
  }

  .admin-custom-row {
    padding: 10px;
  }

  .transaction-amt h6 {
    font-size: 16px;
  }

  .currency-type img {
    max-width: 100px;
    width: 100%;
  }

  .user-name-grid {
    gap: 10px;
    max-width: 280px;
  }

  .action-btn {
    max-width: 180px;
    gap: 10px;
  }

  .withdraw-page .confirm-btn,
  .cancle-btn,
  .confirm-btn {
    max-width: 90px;
  }

  .back-work-btn {
    padding: 0 5px;
  }

  .no-image-area .preview-banner {
    max-width: 98px;
    height: 80px;
  }

  span.add-more {
    max-width: 98px;
    height: 80px;
  }

  .upload-game-thumnail label img {
    height: 150px;
  }

  /* *************************  Setting Page ************************ */

  .setting-page-detail-right h2 {
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 10px;
  }

  .setting-detail-name {
    padding-bottom: 10px;
  }

  .setting-detail-mail {
    padding-bottom: 10px;
  }

  .setting-detail-number {
    padding-bottom: 35px;
  }

  .setting-payment-info {
    padding-bottom: 35px;
  }

  .setting-payment-info p {
    padding-bottom: 15px;
  }

  .setting-payment {
    padding-bottom: 10px;
  }

  .setting-password-info {
    padding-bottom: 35px;
  }

  .setting-password {
    padding-bottom: 10px;
  }

  .setting-delete-text p {
    font-size: 14px;
    line-height: 24px;
  }

  .setting-profile {
    width: 150px;
    height: 150px;
  }

  .setting-page-detail {
    padding: 30px;
  }

  .setting-camera {
    right: 5px;
  }

  .circle img {
    max-width: 150px;
    height: 150px;
  }

  .transparent-btn button,
  .transparent-btn button:hover,
  .transparent-btn button:focus {
    font-size: 14px;
    width: 160px;
    height: 40px;
    border-radius: 10px;
  }

  .red-btn button,
  .red-btn button:hover,
  .red-btn button:focus {
    font-size: 14px;
    width: 160px;
    height: 40px;
    border-radius: 10px;
  }

  .people-list ul {
    height: calc(100vh - 284px);
  }

  .adminchat .adminchat-history ul {
    height: calc(100vh - 388px);
  }
}

@media screen and (max-width: 767px) {
  .field-box {
    padding: 10px;
  }

  .view-deposit-grids {

    padding-right: 10px;
  }

  .field-box span {
    font-size: 14px;
    line-height: 16px;
  }


  .deposit-heading h6 {
    font-size: 20px
  }

  .admin-header {
    padding: 15px;
  }

  .brand-name {
    max-width: 40px;
  }

  .brand-name span {
    display: none;
  }

  .page-header {
    padding: 15px;
  }

  .page-header-title h2 {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 10px;
  }

  .wallet-info {
    gap: 45px;
  }

  .total-coins {
    max-width: 100px;
    width: 100%;
  }

  .total-coins p {
    font-size: 14px;
    gap: 5px;
    flex-direction: column;
  }

  .total-coins p img {
    width: 15px;
    height: 15px;
  }

  .admin-content-wrapper {
    padding: 15px;
  }

  .admin-live-chat .admin-content-wrapper {
    padding-top: 15px;
  }

  .admin-table {
    padding-top: 15px;
  }

  .admin-games-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .admin-section-title {
    padding-bottom: 15px;
  }

  .admin-games,
  .admin-brands,
  .admin-games-content {
    padding-top: 15px;
  }

  .new-game-btn-wrapper {
    gap: 15px;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .admin-section-title button,
  .new-game-btn {
    font-size: 14px;
    padding: 10px;
    border-radius: 10px;
    max-width: 130px;
  }

  .admin-section-title button img,
  .new-game-btn img {
    display: none;
  }

  /* ***************************** Setting page **************************** */
  .setting-page-detail {
    padding: 15px;
    padding-bottom: 30px;
  }

  .setting-page-detail-right {
    padding-top: 30px;
  }

  .setting-name {
    width: 100%;
  }

  .setting-detail-name {
    flex-wrap: wrap;
    gap: 10px;
  }

  .setting-payment {
    flex-wrap: wrap;
    gap: 10px;
  }

  .setting-payment-left {
    width: 100%;
  }

  .setting-payment-right {
    width: 100%;
  }

  .transparent-btn button,
  .transparent-btn button:hover,
  .transparent-btn button:focus {
    width: 100%;
    max-width: 100%;
    height: 40px;
    font-weight: 500;
    border-radius: 10px;
    font-size: 14px;
  }

  .red-btn button,
  .red-btn button:hover,
  .red-btn button:focus {
    width: 100%;
    max-width: 100%;
    height: 40px;
    font-weight: 500;
    border-radius: 10px;
    font-size: 14px;
  }

  /* *************** Delete Popup ***************** */
  .delete-popup .modal-header {
    padding: 20px 20px 0px;
  }

  .delete-popup .btn-close {
    width: 10px;
    height: 10px;
  }

  .delete-popup-content h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .delete-popup-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .delete-popup-footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .delete-popup-footer .btn-secondary,
  .delete-popup-footer .btn-primary {
    border-radius: 10px;
    max-width: 100px;
    padding: 6.5px 0;
    font-size: 16px;
    font-weight: 500;
  }

  .delete-popup-content {
    padding: 20px;
    padding-bottom: 0;
  }

  .admin-game-table,
  .admin-custom-table {
    padding-top: 36px;
  }

  /* .search-wrapper-deposit {
    top: 76px;
  } */

  .admin-section-title h3 {
    font-weight: 500;
    font-size: 22px;
  }

  .offer-tab,
  .special-deal-tab {
    padding-top: 15px;
  }

  .offer-tab-content,
  .special-deal-tab-content {
    padding-top: 15px;
  }

  .create-offer-btn {
    height: 40px;
    font-weight: 500;
  }

  .simple-offer-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .normal-offer-box {
    max-width: 100%;
  }

  .special-offer-popup .btn-close,
  .normal-offer-step1 .btn-close,
  .normal-offer-step2 .btn-close {
    width: 15px;
    height: 15px;
  }

  .normal-offer-step1 .modal-body h5 {
    font-size: 20px;
    padding-bottom: 15px;
    line-height: 20px;
  }

  .select-banner-option {
    gap: 15px;
    justify-content: center;
  }

  .select-banner-option .form-group {
    max-width: 120px;
    padding: 10px;
  }

  .select-banner-option .form-check {
    gap: 5px;
    align-items: center;
  }

  .select-banner-option .form-check-input {
    width: 20px;
    height: 20px;
  }

  .select-banner-option label {
    font-size: 14px;
    line-height: 24px;
  }

  .select-banner-option .form-check-input:checked[type="radio"] {
    width: 20px;
    height: 20px;
    background-position: center;
  }

  .upload-image-area {
    padding-top: 30px;
  }

  .upload-image-area .form-group {
    margin: 15px 0;
  }

  .special-offer-footer {
    gap: 15px;
    padding: 15px 0;
  }

  .special-offer-footer .btn-secondary,
  .special-offer-footer .btn-primary {
    border-radius: 10px;
    max-width: 100px;
    padding: 6.5px 0;
    font-size: 16px;
    font-weight: 500;
  }

  .normal-offer-form-2 {
    padding: 0;
  }

  .area-title h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }

  .normal-offer-step2 .modal-title.h4 {
    font-size: 20px;
    line-height: 24px;
  }

  .normal-offer-form-left .form-group {
    margin-bottom: 10px;
  }

  .normal-offer-form-left label {
    margin-bottom: 5px;
  }

  .normal-offer-form-left .form-control {
    height: 40px;
    padding: 10px;
  }

  .normal-offer-form-left textarea.form-control {
    height: 52px;
  }

  .notification-wrapper.offcanvas {
    max-width: 350px;
    padding: 20px 10px;
  }

  .notification-header {
    padding-bottom: 10px;
  }

  .notification-header h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .notfication-card-header {
    padding: 10px;
  }

  .notfication-card-header h5 {
    font-size: 12px;
    line-height: 16px;
  }

  .notfication-card-header .form-check-input {
    width: 15px;
    height: 15px;
  }

  .notfication-card-body {
    padding: 10px;
  }

  .notfication-card-footer {
    padding: 0 10px 5px;
  }

  .transaction-via img {
    width: 100px;
  }

  .notification-list {
    height: calc(100vh - 156px);
  }

  .notfication-card-header .form-check-input:checked[type="checkbox"] {
    width: 15px;
    height: 15px;
    background-position: center;
  }

  .distributor-request-box {
    width: unset;
  }

  .distributor-request-box:not(:last-child) {
    width: 42%;
  }

  .game-info-popup .btn-close {
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    right: 20px;
    top: 20px;
  }

  .game-info-popup .modal-body {
    padding: 20px;
  }

  .game-info-name {
    padding: 40px 20px;
  }

  .game-info-name h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .game-info-banner img {
    width: 100%;
  }

  .popup-black {
    max-width: 100px;
    padding: 6.5px 0;
    font-weight: 500;
  }

  .game-info-wrapper h6 {
    padding-bottom: 10px;
  }

  .game-info-right p {
    font-weight: 500;
    font-size: 12px;
  }

  .transaction-body-content img {
    max-width: 140px;
    width: 100%;
  }

  .transaction-heading h6 {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 15px;
    max-width: 290px;
  }

  .transaction-heading p {
    font-size: 28px;
    line-height: 30px;
  }

  .cashtag-link {
    padding: 15px;
    max-width: 310px;
  }

  span.link-icon img {
    width: 14px;
  }

  .cashtag-link h6 {
    font-size: 14px;
  }

  .cashtag-link span {
    font-size: 14px;
    line-height: 16px;
  }

  span.link-icon {
    padding: 0;
  }

  .qr-box {
    margin: 20px auto;
    padding: 15px;
  }

  .qr-info p {
    font-size: 14px;
    line-height: 14px;
    padding: 0 10px;
  }

  .withdarw-popup .btn-close {
    width: 15px;
    height: 15px;
  }

  .transaction-body-content {
    padding: 0 15px;
  }

  .withdarw-popup .modal-body {
    padding-bottom: 20px;
  }

  .transaction-heading {
    padding: 20px 0;
  }

  .cashtag-link {
    padding: 10px;
  }

  .new-question-tab,
  .spam-question-tab,
  .answered-tab {
    padding-top: 15px;
  }

  .new-question-tab-content,
  .spam-question-tab-content,
  .answered-tab-content {
    padding-top: 15px;
  }

  .question-wrapper,
  .answer-wrapper,
  .answer-wrapper-show {
    padding: 15px;
  }

  .quetion-time {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .question-by h6 {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 5px;
  }

  .que-action-btn {
    max-width: 190px;
    gap: 10px;
  }

  .send-mail-btn,
  .spam-btn {
    height: 35px;
    padding: 5px;
    max-width: 90px;
    font-weight: 500;
  }

  .que-by-grid span img {
    width: 10px;
    height: 10px;
  }

  .reply-box {
    margin-top: 15px;
    padding-top: 15px;
  }

  .reply-box .form-control {
    padding: 10px;
    height: 50px;
    border-radius: 5px;
  }

  .reply-box button,
  .reply-box button:hover,
  .reply-box button:focus {
    padding: 0;
    width: 50px;
    height: 30px;
    line-height: 14px;
    font-weight: 500;
    border-radius: 5px;
  }

  .user-question-wrapper {
    padding: 0;
    padding-bottom: 15px;
  }

  .admin-answer-list-wrapper {
    padding: 15px;
  }

  .admin-answer-list {
    padding-right: 10px;
  }

  .ans-by-grid {
    gap: 10px;
  }

  .ans-by-img {
    width: 32px;
    height: 32px;
  }

  .ans-by-img img {
    height: 30px;
    width: 30px;
  }

  .auth-page {
    padding: 40px 30px;
    /* height: 100%;
    display: inline-block;
    overflow: hidden; */
  }

  .auth-page h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    padding-top: 10px;
  }

  .auth-page p {
    padding-bottom: 10px;
    line-height: 20px;
    font-size: 12px;
  }

  .forget-password {
    padding-top: 0;
  }

  .l-btn.btn.btn-primary {
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    padding: 5px 40px;
  }

  .distributor-request-wrapper {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .distributor-left,
  .distributor-right {
    width: 100%;
  }

  .distributor-left {
    border-radius: 0px 0px 9px 9px;
  }

  .distributor-full-info h5 {
    padding-bottom: 5px;
  }

  .distributor-info-row {
    padding: 5px 0;
  }

  .markcompleted-btn {
    width: 60%;
    margin: 0px auto;
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  .distributor-msg-section {
    gap: 10px;
    height: 190px;
    padding-right: 15px;
  }

  .admin-msg-content {
    padding: 10px;
  }

  .msg-circle .form-check-input {
    width: 15px;
    height: 15px;
  }

  .msg-circle .form-check-input:checked[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .admin-live-chat .admin-section-title {
    padding-right: 15px;
  }

  .admin-live-chat .admin-section-title button {
    background: transparent;
    border: 1px solid rgb(255 255 255 / 20%);
    border-radius: 8px;
    max-width: 100px;
    height: 35px;
    font-size: 12px;
    padding: 5px;
    gap: 0px;
    display: flex;
  }

  .admin-live-chat .admin-section-title button i.las.la-ellipsis-v {
    font-size: 20px;
  }

  .admin-support-adminchat-content .people-list {
    display: none;
  }

  .adminchat {
    max-width: 100%;
  }

  .adminchat .adminchat-history ul {
    height: calc(100vh - 385px);
  }

  .people-list {
    max-width: 300px;
  }

  .people-list ul {
    height: calc(100vh - 210px);
  }

  .adminchat-header-user {
    width: 100%;
  }

  .adminchat .adminchat-header {
    flex-wrap: wrap;
    row-gap: 5px;
  }

  .adminchat .adminchat-header {
    padding-left: 25px;
  }

  .adminchat-message .form-control {
    padding-right: 75px;
  }

  .adminchat .adminchat-message button {
    min-width: 50px;
    right: 22px;
  }

  .approved-game .my-game-page.wallet-user-recent-game-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .approved-game .recent-game_box img {
    max-width: unset;
  }

  .sub-admin-page .new-game-btn-wrapper {
    flex-direction: row;
  }

  .sub-admin-page .new-game-btn {
    font-size: 12px;
  }

  .new-admin-popup .admin-form-row {
    flex-wrap: wrap;
  }

  .input-icon {
    top: 32px;
  }

  .sub-headings {
    flex-direction: column;
  }

  .last-login-wrapper {
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .admin-games-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .user-loggedin {
    gap: 15px;
  }

  /* *************************  Setting Page ************************ */
  .setting-detail-form input,
  .setting-detail-form input.form-control,
  .setting-detail-form input.form-control:hover,
  .setting-detail-form input.form-control:focus {
    height: 40px;
    font-size: 12px;
    line-height: 20px;
  }

  .setting-payment-info p {
    font-size: 12px;
    line-height: 20px;
  }

  .setting-cash-code,
  .setting-qr-code {
    height: 40px;
  }

  .setting-qr-code p {
    padding-bottom: 0;
  }

  .cash-code-left img {
    height: 32px;
    width: 32px;
  }

  .qr-code-scan,
  .qr-code-scan img {
    height: 32px;
    width: 32px;
  }

  .setting-input img {
    top: 15px;
  }

  .registered-date p,
  .registered-date h6 {
    text-align: right;
  }

  .wallet-info-wrapper p {
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
  .auth-page {
    height: 100%;
  }
}