.offer-tab,
.special-deal-tab {
  padding-top: 30px;
}
.offer-tab-content,
.special-deal-tab-content {
  padding-top: 30px;
}
.simple-offer-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 30px;
}
.special-offer-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}

.create-offer-btn {
  background: #2f1643;
  border: 2px solid #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 50px;
}
.create-offer-btn:hover,
.create-offer-btn:focus,
.create-offer-btn.btn:active:focus,
.create-offer-btn:active {
  background: rgba(232, 0, 84, 0.1);
  border: 2px solid #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
}
.normal-offer-box {
  position: relative;
  min-height: 405px;
  max-width: 300px;
  width: 100%;
}
.normal-offer-box img {
  width: 100%;
  cursor: pointer;
  height: 260px;
  border-radius: 10px 10px 0px 0px;
}
.edit-offer-menu {
  position: absolute;
  top: 20px;
  right: 20px;
}
.edit-offer-menu .btn-primary {
  border-radius: 100%;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  position: relative;
  background: rgb(255 255 255 / 80%);
  line-height: 1px;
}
.edit-offer-menu .btn-primary:hover,
.edit-offer-menu .btn-primary:focus {
  background: rgb(46 139 230 / 80%);
  border: none;
}

.edit-offer-menu .btn-check:active + .btn:focus,
.edit-offer-menu .btn-check:checked + .btn:focus,
.edit-offer-menu .btn.active:focus,
.edit-offer-menu .btn.show:focus,
.edit-offer-menu .btn:active:focus,
.edit-offer-menu .btn:focus {
  box-shadow: none;
}
.special-offer-box .edit-offer-menu .btn-primary {
  background: transparent;
}
.special-offer-box .edit-offer-menu .btn-primary:hover,
.special-offer-box .edit-offer-menu .btn-primary:focus {
  background: transparent;
  border: none;
}

.edit-offer-menu .btn-primary img {
  width: unset;
  height: unset;
  min-height: unset;
  border-radius: unset;
}

.edit-offer-menu .dropdown-menu {
  padding: 15px 0px;
  width: 100%;
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #2e8be6;
  box-shadow: -5px 17px 60px rgb(21 15 47 / 20%);
  backdrop-filter: blur(30px);
  border-radius: 20px;
}

.edit-offer-menu .dropdown-item {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 5px 15px;
  background: transparent;
  border: none;
}

.edit-offer-menu .dropdown-item:focus,
.edit-offer-menu .dropdown-item:hover {
  background: rgb(46 139 230 / 30%);
  border: none;
  border-radius: unset;
}

.edit-offer-menu .dropdown-item img {
  width: unset;
  height: unset;
  min-height: unset;
  border-radius: unset;
}
.edit-offer-menu .dropdown-toggle::after {
  content: unset;
}

.inner-box {
  padding: 0 30px 30px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  position: relative;
  z-index: 9;
}
.inner-box h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.003em;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 202px;
  margin: 0 auto;
  padding-bottom: 5px;
  word-break: break-all;
}
.horizontal-image .inner-box h5 {
  text-align: left;
  max-width: 142px;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  word-break: unset;
  padding-bottom: 16px;
}
.horizontal-image .inner-box h6 {
  text-align: left;
}
.inner-box.orange-box h5 {
  max-width: 165px;
}
.inner-box h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.003em;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 202px;
  margin: 0 auto;
  padding-bottom: 15px;
}
.inner-box button,
.inner-box button:hover,
.inner-box button:active,
.inner-box button:active:focus,
.inner-box button:focus {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 9px;
  max-width: 202px;
  width: 100%;
  box-shadow: none;
}

/* .normal-offer-box:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 285px;
  left: 0;
  bottom: 0;
  border-radius: 0px 0px 10px 10px;
} */
.normal-offer-box.purpule-box::after {
  background: linear-gradient(0deg, #7749f3 59.38%, rgba(119, 73, 243, 0) 100%);
}
.normal-offer-box.orange-box::after {
  background: linear-gradient(0deg, #ff8517 59.38%, rgba(255, 133, 23, 0) 100%);
}

.normal-offer-box.green-box::after {
  background: linear-gradient(0deg, #45b928 59.38%, rgba(68, 184, 39, 0) 100%);
}
.normal-offer-box.violet-box::after {
  background: linear-gradient(0deg, #7748f3 59.38%, rgba(119, 72, 243, 0) 100%);
}
.normal-offer-box .bg-color {
  position: absolute;
  content: "";
  width: 100%;
  height: 285px;
  left: 0;
  bottom: 0;
  border-radius: 0px 0px 10px 10px;
}
.specail-offers-section {
  padding-top: 120px;
}
.special-offer-box {
  background: rgba(66, 85, 183, 0.1);
  border: 1px solid rgba(74, 38, 171, 0.5);
  box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 20px;
  padding: 40px;
}
.special-offer-box h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 10px;
}
.special-offer-box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #aca2b4;
  margin: 0;
  padding-bottom: 33px;
  max-width: 472px;
}
.special-offer-box button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  max-width: 202px;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 9px;
}
.special-offer-box button:hover,
.special-offer-box button:focus {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
  box-shadow: none;
}
.modal-backdrop {
  background: #0e0d27;
  opacity: 0.8 !important;
}
.special-offer-popup .modal-content,
.normal-offer-step1 .modal-content {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 850px;
  margin: 0 auto;
}
.special-offer-popup .modal-header,
.normal-offer-step1 .modal-header {
  border: none;
  padding: 40px 40px 0px;
  justify-content: flex-end;
}
.special-offer-popup .btn-close,
.normal-offer-step1 .btn-close,
.normal-offer-step2 .btn-close {
  background: url("../../assets//images/offer/close-icon.svg") center;
  width: 20px;
  height: 20px;
  opacity: unset;
  border-radius: unset;
  margin: 0;
  padding: 0;
}
.normal-offer-step2 .btn-close {
  background: url("../../assets//images/offer/close-icon-2.svg") center;
}
.special-offer-popup .modal-body,
.normal-offer-step1 .modal-body,
.normal-offer-step2 .modal-body {
  padding: 0px;
}
.special-offer-popup .modal-body h6 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 40px;
}
.special-offer-form .form-group {
  max-width: 455px;
  width: 100%;
  margin: 0px auto;
  margin-bottom: 20px;
}
.special-offer-form label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0e0d27;
  margin-bottom: 10px;
}
.special-offer-form input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
  padding: 15px 20px;
  height: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
}
.special-offer-form textarea {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
  height: 230px;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #0e0d27;
  padding: 20px;
}
.max-limit {
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  text-align: right;
  color: #aca2b4;
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
}
.special-offer-form .form-control::placeholder {
  color: #0e0d27;
}

.special-offer-footer {
  display: flex;
  justify-content: center;
  gap: 30px;
  background: rgb(217 217 217 / 20%);
  padding: 30px 0;
}
.special-offer-footer .btn-secondary {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  max-width: 190px;
  width: 100%;
  padding: 16.5px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #0e0d27;
}
.special-offer-footer .btn-secondary:hover {
  background: transparent;
  border: 1px solid rgb(14 13 39 / 20%);
}
.special-offer-footer .btn-secondary:focus {
  box-shadow: none;
}

.special-offer-footer .btn-primary {
  background: linear-gradient(92.5deg, #e80054 0.69%, #e80054 101.34%);
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  max-width: 190px;
  width: 100%;
  border: none;
  padding: 16.5px 0;
}
.special-offer-footer .btn-primary:focus {
  box-shadow: none;
}

.normal-offer-step1 .modal-body h5 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 30px;
}
.select-banner-area {
  max-width: 456px;
  width: 100%;
  margin: 0px auto;
}
.select-banner-area p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #0e0d27;
  opacity: 0.8;
}
.select-banner-option {
  display: flex;
  justify-content: space-between;
}
.select-banner-option .form-group {
  max-width: 213px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 25px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
}
.select-banner-option .form-check-input {
  background: rgb(14 13 39/5%);
  border: none;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  margin: 0;
}
.select-banner-option .form-check-input:focus {
  box-shadow: none;
}
.select-banner-option .form-check-input:checked[type="checkbox"] {
  background: url("../../assets//images//header/tick.svg") no-repeat;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: none;
  background-position: center;
}

.select-banner-option label {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #0e0d27;
  margin: 0;
}

/* .select-banner-option label{
    font-weight: 700;
}
.select-banner-option .form-group{
    border: 1px solid #E80054;
} */

.upload-image-area {
  max-width: 262px;
  width: 100%;
  margin: 0px auto;
  padding-top: 40px;
}

.horizontal {
  max-width: 554px;
}
.horizontal .safe-zone p {
  margin-left: 30px;
}

.upload-image-area p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #0e0d27;
  margin: 0;
}
.upload-image-area .form-group {
  height: 380px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.upload-image-area input[type="file"] {
  height: 30px;
  margin: 10px 0px;
  padding: 5px;
}

.upload-image-area .custom-file,
.upload-image-area .custom-file-input {
  position: relative;
  width: 100%;
  height: 28px;
}
.upload-image-area .custom-file [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  display: none;
}
.upload-image-area .custom-file [type="file"]:focus {
  border: none;
  box-shadow: none;
}

.upload-image-area .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  padding: 0px;
  cursor: pointer;
  background: #f3f3f4;
  border: 1px dashed #0e0d27;
  border-radius: 10px;
  height: 380px;
  margin: 0;
}
.upload-image-area .custom-file-label img {
  object-fit: cover;
  width: 100%;
  height: 380px;
}
.upload-banner-content {
  max-width: 148px;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding-top: 90px;
}
.horizontal .upload-banner-content {
  max-width: 256px;
}

.upload-banner-content h6,
.upload-banner-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #0e0d27;
  opacity: 0.8;
  margin: 0;
}
.upload-banner-content span {
  font-weight: 700;
  text-decoration: underline;
}

p.make-sue {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.003em;
  color: #0e0d27;
  opacity: 0.3;
  max-width: 167px;
  margin: 0px auto;
  padding-top: 32px;
  padding-bottom: 38px;
}
.horizontal p.make-sue {
  padding-top: 20px;
  padding-bottom: 75px;
}
.safe-zone span {
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: rgb(14 13 39 / 80%);
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.safe-zone span:after,
.safe-zone span:before {
  background: rgb(14 13 39 / 20%);
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  width: 38%;
}
.safe-zone span:after {
  right: 0;
}
.safe-zone span:before {
  left: 0;
}
.safe-zone p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  max-width: 202px;
  margin: 0px auto;
  height: 40px;
  background: rgb(14 13 39 / 20%);
  border-radius: 6px;
}
.normal-offer-step2 .modal-header {
  border: none;
  padding: 30px 40px 30px 60px;
  background: rgb(46 139 230 / 10%);
}
.normal-offer-step2 .modal-title.h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0e0d27;
}
.normal-offer-step2 .modal-content {
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(14, 13, 39, 0.5);
  border-radius: 10px;
  max-width: 1140px;
  margin: 0 auto;
}
.normal-offer-form-2 {
  display: flex;
  padding: 40px 60px 65px 60px;
}
.normal-offer-form-left {
  max-width: 540px;
  width: 100%;
  padding-right: 90px;
  border-right: 1px solid rgb(0 0 0 / 10%);
}
.area-title {
  padding-bottom: 30px;
}
.area-title h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #0e0d27;
  margin: 0;
}
.normal-offer-form-left .form-group {
  margin-bottom: 20px;
}
.normal-offer-form-left label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0e0d27;
  margin-bottom: 10px;
}
.normal-offer-form-left .form-control {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(124 116 142 / 20%);
  border-radius: 4px;
  padding: 15px 20px;
  height: 52px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e0d27;
}
.normal-offer-form-left .form-control:focus {
  box-shadow: none;
}
.normal-offer-form-left textarea {
  font-weight: 400 !important;
}
.normal-offer-form-left .form-control::placeholder {
  color: #0e0d27;
}
.edit-image-area {
  padding-top: 10px;
}
.edit-image-area p {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
}
.edit-image-span {
  font-weight: 400;
  letter-spacing: 0.003em;
  padding-left: 5px;
}
.edit-image-area .form-group {
  height: 164px;
  margin-top: 10px;
  margin-bottom: 30px;
  max-width: 165px;
}

.edit-image-area input[type="file"] {
  height: 30px;
  margin: 10px 0px;
  padding: 5px;
}

.edit-image-area .custom-file,
.edit-image-area .custom-file-input {
  position: relative;
  width: 100%;
  height: 28px;
}
.edit-image-area .custom-file [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  display: none;
}
.edit-image-area .custom-file [type="file"]:focus {
  border: none;
  box-shadow: none;
}

.edit-image-area .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  padding: 0px;
  cursor: pointer;
  background: #f3f3f4;
  border-radius: 10px;
  height: 164px;
  margin: 0;
}
.uploaded-image img {
  max-width: 165px;
  width: 100%;
  height: 164px;
}

.uploaded-image-area {
  position: relative;
}
.small-image {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
}
.bg-color-wrapper h6 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0e0d27;
  margin: 0;
  padding-bottom: 16px;
}
.bg-color-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 14px;
}
.bg-color-head {
  border-radius: 6px 6px 0px 0px;
  width: 80px;
  height: 56px;
  cursor: pointer;
}
.green-bg {
  background: #45b928;
}
.purple-bg {
  background: #7748f3;
}
.orange-bg {
  background: #ff8517;
}
.blue-bg {
  background: #4878f3;
}
.pink-bg {
  background: #b92381;
}
.bg-color-footer {
  background: #f2f2f5;
  border-radius: 0px 0px 6px 6px;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #0e0d27;
  padding: 6px;
  width: 80px;
}

.normal-offer-form-right {
  max-width: calc(100% - 540px);
  width: 100%;
  padding-left: 90px;
}
.normal-offer-form-right.horizontal-image {
  padding-left: 85px;
}
.horizontal-image .normal-offer-box {
  max-width: 100%;
  display: flex;
  flex-direction: row-reverse;
  min-height: unset;
}
.horizontal-image .normal-offer-box img {
  height: 266px;
  max-width: 208px;
}
.horizontal-image  .inner-box {
  padding: 50px 0px 20px 20px;
  width: 100%;
}
.horizontal-image .normal-offer-box .bg-color {
  width: 266px;
  height: 266px;
  transform: rotate(90deg);
}
.horizontal-image .inner-box button, .horizontal-image .inner-box button:hover, .horizontal-image .inner-box button:active, .horizontal-image .inner-box button:active:focus, .horizontal-image .inner-box button:focus {
  position: absolute;
  bottom: 20px;
  left: 20px;
  max-width: 142px;
}

.edit-offer-menu .btn-primary:hover svg path,
.edit-offer-menu .btn-primary:hover svg circle,
.edit-offer-menu .btn-primary:focus svg path,
.edit-offer-menu .btn-primary:focus svg circle {
  fill: #ffffff;
}

/****************************** radio button **********************************/

.select-banner-option .form-check-input:checked[type="radio"] {
  background-image: url("../../assets/images/offer/radioIcon.svg");
}
.select-banner-option .form-check {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
}
.select-banner-option .form-group.active {
  border: 1px solid #e80054;
}
.normal-offer-form-2 .select-banner-option .form-group:not(.active) {
  cursor: not-allowed;
}
.bg-color-box {
  position: relative;
}

.checkbox-span {
  position: absolute;
  right: 6px;
  top: 6px;
}
.checkbox-span img{
  height: 18px;
  width: 18px
}
