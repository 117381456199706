.new-distributor-tab, .complete-distributor-tab{
    padding-top: 30px;
}
.new-distributor-tab-content, .complete-distributor-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
}
.distributor-request-wrapper{
    background: rgba(66, 85, 183, 0.1);
    border: 1px solid rgba(66, 85, 183, 0.5);
    box-shadow: -5px 17px 60px rgba(21, 15, 47, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    display: flex;
}
.distributor-left{
    background: rgb(46 139 230 / 10%);
    border-radius: 9px 0px 0px 9px;
    max-width: 445px;
    width: 100%;
    padding: 30px;
}
.distributor-right{
    max-width: calc(100% - 445px);
}
.distributor-full-info h5{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}
.distributor-info-row{
    border-bottom: 1px dashed rgb(255 255 255 / 20%);
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.distributor-info-row h6{
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.5;
    margin: 0;
}
.distributor-info-row p, .distributor-info-row a, .distributor-info-row a:hover{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #2E8BE6;
    margin: 0;
}
.distributor-info-row a{
    text-decoration: underline;
}
.markcompleted-btn{
    background: rgba(232, 0, 84, 0.1);
    border: 2px solid #E80054;
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    border-radius: 10px;
    width: 100%;
    margin-top: 30px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
.markcompleted-btn:hover{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    border: 2px solid #E80054;
}
.markcompleted-btn:focus, .markcompleted-btn:active:focus{
    border: 2px solid #E80054;
    box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
}
.distributor-right{
    padding: 20px 30px 30px;
}
.distributor-msg-header {
    display: flex;
    justify-content: space-between;
}
.msg-circle .form-check-input {
    background: rgb(255 255 255 / 10%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: none;
}
.msg-circle .form-check-input:focus {
    box-shadow: none;
    border: none;
}
.msg-circle .form-check-input:checked[type=checkbox] {
    background: url("../../assets//images//header/tick.svg");
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: none;
    background-position: center;
}
.distributor-msg-header{
    padding-bottom: 10px;
}
.distributor-msg-header h5{
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}
.distributor-msg-content p{
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}
.distributor-msg-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 255px;
    overflow: auto;
    padding-right: 30px;
}

.distributor-msg-section::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.distributor-msg-section::-webkit-scrollbar-track {
    background:  rgb(255 255 255 / 10%);
    width: 1px;
}
  
.distributor-msg-section::-webkit-scrollbar-thumb {
    background-color: #E80054;
 }
.admin-msg-content{
    padding: 20px;
    border-left: 1px solid rgb(255 255 255 / 20%);
}
.admin-msg-content p{
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: rgb(255 255 255 / 60%);
    margin: 0;
    padding-top: 10px;
}
.admin-msg-content .ans-by-grid{
    gap: 10px;
}
.admin-msg-content .ans-by-img{
    width: 20px;
    height: 20px;
    line-height: 0;
}
.admin-msg-content .ans-by-img img{
    width: 18px;
    height: 18px;
}
.admin-msg-content .ans-by-heading h6{
    font-size: 12px;
}
.admin-typing-section .reply-box{
    border: none;
    margin-top: 20px;
    padding: 0;
}
  .distributor-request-wrapper.active {
    background: rgba(66, 85, 183, 0.2);
    border: 2px solid #4255B7;
}
.back-work-btn{
    background: linear-gradient(92.5deg, #E80054 0.69%, #E80054 101.34%);
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
    border-color: transparent;
    border-radius: 6px;
    max-width: 83px;
    width: 100%;
    height: 26px;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 0;
}
.back-work-btn:hover, .back-work-btn:focus, .back-work-btn:active:focus{
    background: transparent;
    border-color: #E80054;
    box-shadow: 0px 10px 20px -5px rgba(232, 0, 84, 0.25);
}
.complete-distributor-tab .admin-table .table tbody td{
    padding: 12px 10px 12px 30px;
}


  

