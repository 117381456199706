.setting-page-detail {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 5%);
  padding: 60px;
  border-radius: 10px;
  justify-content: space-between;
}

.setting-page-content h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 30px;
}

.setting-page-content {
  background: url(../../assets/images/setting/bg.png) no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.circle {
  width: 100%;
  position: relative;
  text-align: center;
}

.setting-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: #2f2e5c;
  border-radius: 50%;
  position: relative;
}

.circle img {
  max-width: 200px;
  height: 200px;
  width: 100%;
  border-radius: 100%;
}

.upload-image-icon input {
  height: 0;
  width: 0;
}

.upload-image-icon label {
  cursor: pointer;
}

.setting-camera {
  position: absolute;
  right: 20px;
  bottom: 5px;
  cursor: pointer;
}
.setting-page-detail-right {
  max-width: calc(100% - 258px);
  width: 100%;
}

.setting-page-detail-right h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
}

.setting-page-detail-right h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}

.setting-detail-form label {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding-bottom: 10px;
}

.setting-detail-name {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding-bottom: 20px;
}

.setting-name {
  max-width: 100%;
  width: 50%;
}

.setting-detail-mail {
  padding-bottom: 20px;
}

.setting-detail-number {
  padding-bottom: 50px;
}

.setting-payment-info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgb(255 255 255 / 30%);
  margin: 0;
  padding: 0 0 25px 0;
}

.setting-payment {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 20px;
}
.setting-payment:last-child {
  padding: 0;
}

.setting-payment-left {
  max-width: 100%;
  width: 60%;
}

.setting-payment-right {
  max-width: 100%;
  width: 40%;
}
.setting-qr-code-label label {
  width: 100%;
  padding: 0;
}

.setting-payment-info {
  padding-bottom: 50px;
}

.setting-password-info h2 {
  padding-bottom: 20px;
}

.setting-password-info {
  padding-bottom: 50px;
}

.setting-delete-account {
  background: #121338;
  border: 1px solid rgba(74, 38, 171, 0.5);
  border-radius: 10px;
  padding: 35px 30px 30px 30px;
}

.setting-delete-account h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 15px;
}

.setting-delete {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.setting-delete-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  max-width: 395px;
  margin: 0;
}
.setting-payment-info h2 {
  padding-bottom: 5px;
}
.setting-detail-form input,
.setting-detail-form input.form-control,
.setting-detail-form input.form-control:hover,
.setting-detail-form input.form-control:focus {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  height: 50px;
  width: 100%;
}
.react-tel-input .country-list {
  background-color: #26282e;
}
.react-tel-input .country-list .country.highlight, .react-tel-input .country-list .country:hover {
  background: #1e2024;
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
  box-shadow: none;
  border-color: transparent;
}
input::placeholder {
  color: #fff !important;
}
.setting-password {
  padding-bottom: 20px;
}
.setting-detail-form input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
}

.setting-password {
  padding-bottom: 20px;
}

.setting-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.transparent-btn button,
.transparent-btn button:hover,
.transparent-btn button:focus {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  width: 220px;
  height: 60px;
}

.red-btn button,
.red-btn button:hover,
.red-btn button:focus {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  background: #e80054;
  box-shadow: 0px 19px 30px -5px rgba(232, 0, 84, 0.25);
  border-radius: 16px;
  border: none;
  width: 220px;
  height: 60px;
}
.setting-input {
  position: relative;
}

.setting-input img {
  position: absolute;
  top: 20px;
  right: 19px;
  cursor: pointer;
}

.setting-cash-code {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 4px;
  height: 50px;
}

.cash-code-left img {
  background: #fff;
  border-radius: 6px;
}
.cash-code-left span {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding-left: 11px;
}

.cash-code-right {
  padding-right: 11px;
  cursor: pointer;
}
.qr-code-scan {
  border: 1px dashed #ffffff66;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qr-code-scan img {
  width: 42px;
  height: 42px;
}

.setting-qr-code {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #7c748e;
  border-radius: 4px;
  padding: 4px 5px;
  height: 50px;
  cursor: pointer;
}
.setting-qr-code-input {
  display: none;
}
.setting-qr-code span {
  letter-spacing: 0.003em;
  color: #ffffff80;
  text-decoration: underline;
}

.setting-qr-code p {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.003em;
  color: #ffffff80;
  padding-left: 10px;
}
.setting-delete-text p a {
  color: #e80054;
  text-decoration: none;
  padding: 0px 6px;
}

.setting-delete-btn button {
  background: rgb(255 255 255 / 20%);
  border-radius: 8px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  width: 139px;
  height: 40px;
}
.setting-delete-btn button:hover,
.setting-delete-btn button:focus, .setting-delete-btn button:active, .setting-delete-btn button:active:focus {
  box-shadow: none;
  background: rgb(255 255 255 / 20%);
  border: none;
}

.setting-password:last-child {
  padding-bottom: 0px;
}
