.admin-recharge-request .admin-custom-row{
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.admin-game-cancle .confirm-btn{
    max-width: 120px;
    padding: 10px;
}
.reacharge-amt-grid {
    display: flex;
    max-width: 90px;
    width: 100%;
}
.reacharge-amt p{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgb(255 255 255 / 60%);
    margin: 0;
}
.reacharge-amt h6{
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.003em;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    gap: 5px;
    align-items: center;
}
.admin-recharge-confirm .confirm-btn, .admin-recharge-cancel .confirm-btn{
    max-width: 120px;
}