/******** Search Box*********/
.search-wrapper {
  width: 100%;
  position: relative;
}
.search-box {
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.search-box form {
  position: relative;
}

.search-box input,
.search-box input:focus {
  background: rgb(255 255 255 / 10%);
  border-radius: 8px;
  border: none;
  box-shadow: none;
  padding: 10px 20px;
  padding-left: 40px;
  height: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: rgb(255 255 255 / 40%);
}

.search-box button,
.search-box button:hover,
.search-box button:focus {
  background: transparent;
  border-color: transparent;
  position: absolute;
  left: 0;
  color: #E80054;
  box-shadow: none;
  transform: rotate(-90deg);
  font-size: 18px;
}

.search-box input::placeholder {
  color: rgb(255 255 255 / 40%);
}
