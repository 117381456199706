.pagination-sec {
  width: 100%;
}

.pagination-sec .pagination {
  justify-content: center;
  padding-top: 30px;
  margin: 0;
  flex-wrap: wrap;
  gap: 5px;
}

.pagination-sec .page-link {
  border: none;
  width: 30px;
  height: 30px;
  text-align: center;
  background: rgb(255 255 255 / 20%);
  border-radius: 5px;
  margin: 0 !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding-left: 0;
  padding-right: 0;
}

.pagination-sec .page-item.active .page-link {
  background: rgb(232 0 84 / 30%);
  border: none;
  color: #e80054;
}

.pagination-sec .page-link:focus {
  box-shadow: none;
}

/* .pagination-sec .page-item:first-child a:first-child,
.pagination-sec .page-item:last-child a:first-child {
    letter-spacing: 0px;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    display: inline-flex;
    align-items: center;
}

.pagination-sec .page-item:first-child a:first-child:before {
    content: "\f100";
}

.pagination-sec .page-item:last-child a:first-child:before {
    content: "\f101";
} */
