.admin-wrapper {
    display: flex;
    overflow-x: hidden;
}

.admin-view .main_content {
    padding: 0;
}

.admin-container {
    background: url("../../assets/images/bg-admin.png") #1F1842;
    background-size: cover;
    margin-left: 270px;
    max-width: calc(100% - 270px);
    width: 100%;
    min-height: calc(100vh - 70px);
    height: 100%;
}