.new-admin-popup .admin-form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.sub-admin-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-form-row .form-group {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.admin-form-row input.form-control {
  width: 100%;
}

.form-group-password{
    position: relative;
}

.new-admin-popup .react-tel-input .form-control {
  padding: 18.5px 14px 18.5px 60px;
}

.new-admin-popup .react-tel-input .flag-dropdown ul li span.country-name {
  color: #ededed;
}